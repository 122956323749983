.chart{
	width: 520px;
}

.cardTitle{
	font-size: 1rem;
	text-align: center;
}
.cardContent{
	font-size: 1.50rem;
	text-align: center;
	font-weight: bold;
}
.pageHeading{
	height: 160px;
}