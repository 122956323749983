
.signIn {
    background: url("../../assets/images/login_screen.jpg");
    display: flex;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: right;
    align-items: center;
    flex-direction: row-reverse;
  }

.signInContainer {
    .signInForm {
      width: 310px;
      background: rgba(255, 255, 255, 0.5);
      margin: 0 auto;
      text-align: center;
      padding: 30px 15px;
      color: #6b6d6c;
      font-size: 20px;
      border-radius: 5%;
      
    }
    width: 380px;
    position: relative;
    padding: 88px 0;
    display: flex;
    margin-right: 0px;
  }

  .headiconbg{
    color: white;
   }
  .signInuserIcon {
    position: absolute;
    text-align: center;
    padding: auto 0;
    top: 85px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #207588;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  